html, body {
    height: 100%;
    overflow-x: hidden;
  }
  .app-container {
    min-height: 200vh; /* Ensure there's enough height to scroll */
  }
  
.name-header {
  font-family: 'Junicode', serif;
  font-size: 3rem;
  text-align: center;
  color: #0a3;
  text-shadow: 0 0 5px rgba(10, 255, 10, 0.3);
  margin: 2rem 0;
  font-weight: bold;
  letter-spacing: 4px;
}

.app-container {
  min-height: 100vh;
  padding: 20px;
  background: #1a1a1a;
}
  