@import url('../styles/fonts.css');

/* Global Reset & Base Settings */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* ---------------------------------------
   CRT Monitor Container (Not Found)
---------------------------------------- */
.notFoundContainer {
  font-family: 'Junicode', serif;
  padding: 1.5rem;
  color: #333;
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 90%;
  text-align: left;
  z-index: -8;
  transform: translateZ(0);

  /* Apply the SVG filter for CRT effect */
  -webkit-filter: url(#crtCurved);
  filter: url(#crtCurved);

  /* Primary animations for text shadow and screen flicker */
  animation: textShadow 2s infinite, screenFlicker 2s infinite;
}

/* Safari Fallback: Disable filter and use alternative animations */
.safari .notFoundContainer {
  -webkit-filter: none;
  filter: none;
  animation: textShadowSafari 2s infinite, screenFlickerSafari 2s infinite;
}

/* ---------------------------------------
   Animations
---------------------------------------- */
@keyframes textShadow {
  0%, 100% {
    text-shadow: 0.5px 0 1px rgba(0,30,255,0.3),
                 -0.5px 0 1px rgba(255,0,80,0.2),
                 0 0 2px;
  }
  50% {
    text-shadow: 1px 0 1px rgba(0,30,255,0.3),
                 -1px 0 1px rgba(255,0,80,0.2),
                 0 0 2px;
  }
}

@keyframes screenFlicker {
  0%, 100% { opacity: 1; }
  10% { opacity: 0.98; }
  20% { opacity: 0.99; }
  30% { opacity: 0.98; }
  40% { opacity: 1; }
  50% { opacity: 0.99; }
  60% { opacity: 1; }
  70% { opacity: 0.98; }
  80% { opacity: 1; }
  90% { opacity: 0.99; }
}

/* Safari Alternative: Subtler Text Shadow Animation */
@keyframes textShadowSafari {
  0%, 100% {
    text-shadow: 0.3px 0 0.8px rgba(0,30,255,0.2),
                 -0.3px 0 0.8px rgba(255,0,80,0.1),
                 0 0 1.5px;
  }
  50% {
    text-shadow: 0.5px 0 0.8px rgba(0,30,255,0.2),
                 -0.5px 0 0.8px rgba(255,0,80,0.1),
                 0 0 1.5px;
  }
}

/* Safari Alternative: Subtler Screen Flicker */
@keyframes screenFlickerSafari {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.98; }
}

/* ---------------------------------------
   Grain Overlay Effect
---------------------------------------- */
.notFoundContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAIAAADYYG7VAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAALEwAACxMBAJqcGAAAAJ1JREFUaN7t0rENgCAQBECe8/9aTGKEMxJv0og8VZ89NJBAMy/17LALyVVVXV1dX1+q/jNgbXwBa8D0fYALuP0/QDqAtMAvIF0Z1YgF9AcQJqHqCM4+v/dHhV0HoBN+3B8dXQbECsdJ83/jVw4nVgAAAABJRU5ErkJggg==') repeat;
  opacity: 0.08;
  pointer-events: none;
  z-index: 2;
  animation: grain 8s steps(10) infinite;
}

@keyframes grain {
  0%   { transform: translate(0, 0); }
  10%  { transform: translate(-5%, -5%); }
  20%  { transform: translate(-10%, 5%); }
  30%  { transform: translate(5%, -10%); }
  40%  { transform: translate(0, 5%); }
  50%  { transform: translate(-5%, 0); }
  60%  { transform: translate(5%, 5%); }
  70%  { transform: translate(0, -5%); }
  80%  { transform: translate(5%, 0); }
  90%  { transform: translate(-5%, 5%); }
  100% { transform: translate(0, 0); }
}

/* ---------------------------------------
   Content Styling
---------------------------------------- */
.title {
  font-family: 'Junicode-bold', serif;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

.text {
  font-family: 'Junicode', serif;
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
  margin-bottom: 1rem;
}
