.appContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  position: relative;
}

/* Position the header outside (above or to the side of) the monitor */
.headerGroup {
  position: fixed;
  top: 30px;
  left: 50px;
  z-index: 20;
  text-align: left;
}

/* Style for the name header */
.nameHeader {
  font-family: 'Junicode-italic', serif;
  font-size: 1.8rem;
  color: #000;
  margin: 0;
  letter-spacing: 1.5px;
  font-weight: bold;
  pointer-events: none;
}

/* Contact info styling */
.contactInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px; /* Adjust spacing as needed */
}

.contactInfo a {
  font-family: 'FoulisGreek', serif;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
}

.contactInfo a:hover {
  color: #333;
  text-decoration: underline;
}

.arrow {
  width: 12px;
  height: 10px;
  margin-left: 4px;
  vertical-align: middle;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  text-align: center;
  font-family: 'Junicode', serif;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .nameHeader {
    font-size: 1.2rem;
  }
  .contactInfo {
    font-size: 0.8rem;
  }
  .arrow {
    width: 10px;
    height: 8px;
  }
}
