@import url('../styles/fonts.css');

/* Global Reset & Base Settings */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* ---------------------------------------
   CRT Monitor Container (Portfolio)
---------------------------------------- */
.portfolioContainer {
  font-family: 'Junicode', serif;
  padding: 1.5rem;
  color: #333;
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 90%;
  text-align: left;
  z-index: -8;
  transform: translateZ(0);
  
  /* Apply the SVG filter for CRT effect */
  -webkit-filter: url(#crtCurved);
  filter: url(#crtCurved);
  
  /* Primary animations for text shadow and flicker */
  animation: textShadow 2s infinite, screenFlicker 2s infinite;
}

/* Safari Fallback: Disable filter and use alternative (or no) animations */
.safari .portfolioContainer {
  -webkit-filter: none;
  filter: none;
  /* Optionally, use subtler animations or disable them */
  animation: textShadowSafari 2s infinite, screenFlickerSafari 2s infinite;
  /* To completely disable animations, use: animation: none; */
}

/* ---------------------------------------
   Animations
---------------------------------------- */

/* Primary Text Shadow Animation */
@keyframes textShadow {
  0%, 100% {
    text-shadow: 0.5px 0 1px rgba(0,30,255,0.3),
                 -0.5px 0 1px rgba(255,0,80,0.2),
                 0 0 2px;
  }
  50% {
    text-shadow: 1px 0 1px rgba(0,30,255,0.3),
                 -1px 0 1px rgba(255,0,80,0.2),
                 0 0 2px;
  }
}

/* Primary Screen Flicker Animation */
@keyframes screenFlicker {
  0%, 100% { opacity: 1; }
  10% { opacity: 0.98; }
  20% { opacity: 0.99; }
  30% { opacity: 0.98; }
  40% { opacity: 1; }
  50% { opacity: 0.99; }
  60% { opacity: 1; }
  70% { opacity: 0.98; }
  80% { opacity: 1; }
  90% { opacity: 0.99; }
}

/* Safari Alternative: Subtler Text Shadow Animation */
@keyframes textShadowSafari {
  0%, 100% {
    text-shadow: 0.3px 0 0.8px rgba(0,30,255,0.2),
                 -0.3px 0 0.8px rgba(255,0,80,0.1),
                 0 0 1.5px;
  }
  50% {
    text-shadow: 0.5px 0 0.8px rgba(0,30,255,0.2),
                 -0.5px 0 0.8px rgba(255,0,80,0.1),
                 0 0 1.5px;
  }
}

/* Safari Alternative: Subtler Screen Flicker */
@keyframes screenFlickerSafari {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.98; }
}

/* Optional: Scanlines Animation (if needed) */
@keyframes scanlines {
  0% { transform: translateY(0); }
  100% { transform: translateY(3px); }
}

/* ---------------------------------------
   Grain Overlay Effect
---------------------------------------- */
.portfolioContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAIAAADYYG7VAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAALEwAACxMBAJqcGAAAAJ1JREFUaN7t0rENgCAQBECe8/9aTGKEMxJv0og8VZ89NJBAMy/17LALyVVVXV1dX1+q/jNgbXwBa8D0fYALuP0/QDqAtMAvIF0Z1YgF9AcQJqHqCM4+v/dHhV0HoBN+3B8dXQbECsdJ83/jVw4nVgAAAABJRU5ErkJggg==') repeat;
  opacity: 0.08;
  pointer-events: none;
  z-index: 2;
  animation: grain 8s steps(10) infinite;
}

/* Grain Overlay Animation */
@keyframes grain {
  0%   { transform: translate(0, 0); }
  10%  { transform: translate(-5%, -5%); }
  20%  { transform: translate(-10%, 5%); }
  30%  { transform: translate(5%, -10%); }
  40%  { transform: translate(0, 5%); }
  50%  { transform: translate(-5%, 0); }
  60%  { transform: translate(5%, 5%); }
  70%  { transform: translate(0, -5%); }
  80%  { transform: translate(5%, 0); }
  90%  { transform: translate(-5%, 5%); }
  100% { transform: translate(0, 0); }
}

/* ---------------------------------------
   Content Styling (above Effects)
---------------------------------------- */
.title,
.section,
.projectsList,
.contact {
  position: relative;
  z-index: 3;
}

/* Title */
.title {
  font-family: 'Junicode-bold', serif;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Section */
.section {
  padding: 0 0.25rem;
  position: relative;
}

/* Section Divider */
.section:not(:last-child) {
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}
.section:not(:last-child)::after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  margin: 1rem 0;
}

/* Section Titles */
.sectionTitle {
  font-family: 'Junicode-bold', serif;
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
  color: #333;
}

/* Paragraphs & Lists */
.projectsList,
.contact,
p {
  font-family: 'Junicode', serif;
  color: #333;
  line-height: 1.5;
}

/* Project List */
.projectsList {
  list-style: none;
  padding: 0 0.5rem;
}

/* Individual Project Item */
.projectItem {
  margin-bottom: 0.4rem;
  font-size: 1rem;
}

/* Contact Section */
.contact {
  font-family: 'Junicode-italic', serif;
  font-style: italic;
}
.contact a {
  color: #3498db;
  text-decoration: none;
}
.contact a:hover {
  text-decoration: underline;
}

/* ---------------------------------------
   Skills Section
---------------------------------------- */
.skillsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 1rem;
}
.skillCategory {
  background: transparent;
  padding: 0.5rem;
  border: none;
}
.skillCategoryTitle {
  font-family: 'Junicode-bold', serif;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #333;
}
.skillsList {
  list-style: none;
  padding: 0;
  margin: 0;
}
.skillsList li {
  font-family: 'Junicode', serif;
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}
.skillsList li:before {
  content: none;
}

/* ---------------------------------------
   Mobile (Small Screen) Overrides
---------------------------------------- */
@media (max-width: 600px) {
  .portfolioContainer {
    display: none;
  }
}
