@font-face {
    font-family: 'Junicode';
    src: url('/public/fonts/junicode-1.002/Junicode.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Junicode-bold';
    src: url('/public/fonts/junicode-1.002/Junicode-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Junicode-italic';
    src: url('/public/fonts/junicode-1.002/Junicode-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Junicode-bold-italic';
    src: url('/public/fonts/junicode-1.002/Junicode-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
        }

@font-face {
    font-family: 'FoulisGreek';
    src: url('/public/fonts/junicode-1.002/FoulisGreek.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} 