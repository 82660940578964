/* MonitorFrame.css */

/* Wrapper that holds both the monitor and the attached contact info */
.monitor-wrapper {
  position: fixed;
  width: 600px;  /* Same width as .monitor for perfect alignment */
  height: 550px; /* Same height as .monitor */
  margin: 0 auto; /* Center horizontally (optional) */
}

/* The monitor container (as before) */
.monitor {
  width: 600px;
  height: 550px;
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

/* Monitor image, buttons, screen, etc. (unchanged) */
.monitor-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  z-index: 999;
  filter: drop-shadow(1px 4px 2px rgba(37, 37, 37, 0.355));
}

.monitor-button-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
}

.monitor-button {
  position: absolute;
  width: 13px;
  height: 13px;
  background: transparent;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  pointer-events: auto;
  transition: transform 0.1s ease, box-shadow 0.1s ease;

}

.monitor-button:active {
  filter: blur(1px);
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.306);
  filter: blur(1px);
}

.screen {
  position: absolute;
  top: 12%;
  left: 10%;
  width: 80%;
  height: 390px;
}

.scrollContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: transparent;
  color: #000;
  font-family: "Courier New", Courier, monospace;
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
}

.crtOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  z-index: -1;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.872) 0%, rgb(148, 148, 148) 100%);
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.2), inset 0 0 20px rgba(0, 0, 0, 0.62);
  animation: crtFlicker 7s infinite;
}

@keyframes crtFlicker {
  0% { opacity: 0.95; }
  5% { opacity: 0.85; }
  10% { opacity: 0.90; }
  15% { opacity: 0.85; }
  20% { opacity: 0.95; }
  25% { opacity: 0.90; }
  30% { opacity: 0.95; }
  35% { opacity: 1; }
  40% { opacity: 0.90; }
  45% { opacity: 0.95; }
  50% { opacity: 0.95; }
  55% { opacity: 0.90; }
  60% { opacity: 0.80; }
  65% { opacity: 0.95; }
  70% { opacity: 0.90; }
  75% { opacity: 0.85; }
  80% { opacity: 1; }
  85% { opacity: 0.90; }
  90% { opacity: 0.95; }
  95% { opacity: 0.90; }
  100% { opacity: 0.95; }
}

/* Contact info attached to the outside of the monitor */
.contact-attach {
  position: absolute;
  top: 42.5px;    /* Adjust vertically as needed */
  left: -90px; /* Shift left so it "attaches" to the monitor's edge */
  width: 400px; /* Adjust as needed for your text */
  z-index: 2;
  text-align: left;
}

/* Example styling for your name and contact info */
.nameHeader {
  font-family: 'Junicode-italic', 'Times New Roman', serif;
  font-size: 1.8rem;
  color: #000;
  margin: 0;
  letter-spacing: 1.5px;
  font-weight: bold;
  pointer-events: none;
  margin-bottom: 25px;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.contactInfo a {
  font-family: 'FoulisGreek', 'Times New Roman', serif;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
  width: 25%;
}

.contactInfo a:hover {
  color: #333;
  text-decoration: underline;
}

.arrow {
  width: 12px;
  height: 10px;
  margin-left: 4px;
  vertical-align: middle;
}

/* Hide the monitor on smaller screens */
@media (max-width: 600px) {
  .monitor {
    display: none;
  }
  .contact-attach {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .nameHeader {
    margin-bottom: 10px;
    font-size: 1.5rem;
    margin-left: 150px;
    width: 400px;
    margin-bottom: 20px;
  }
  .contactInfo {
    margin-left: 60px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;

  }
}
.power-button {
  width: 27px;
  height: 27px;
  border-radius: 50%;
}

/* You can also add a pressed effect if you like */
.power-button:active {
  filter: blur(1px);
  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.4);
}